import markupParser from 'html-react-parser';
import { get, isString, set } from 'lodash';
import {
  Locale,
  Messages,
  Namespace,
  NamespaceDescriptor,
  NamespacesReadyFn,
  RequestId,
  RequestStatus,
  TranslateFn,
  TranslateGroupFn,
  TranslateHtmlFn,
} from './types';

// Transverse stack
const transversePrefix = 'transverse.';

const emptyReplace = {};

const sanitizeKey = (key: string) =>
  key
    .replace(/([A-Z][a-z0-9])/g, '_$1')
    .toLowerCase()
    .replace(/\._/g, '.');

const normalizePath = (messages: Messages, path: string) =>
  Object.keys(messages).find((key) => new RegExp(`^${sanitizeKey(path)}\$`).test(key)) || [];

export const namespacesReady =
  (requests: Record<RequestId, RequestStatus>, locale: Locale): NamespacesReadyFn =>
  (countryCode: string, ...rawNamespaces: Namespace[]) => {
    const namespaces = reduceNamespaces(rawNamespaces, countryCode);
    return namespaces.every(
      (namespace) =>
        requests[`${locale}.${namespace}`] === RequestStatus.Error ||
        requests[`${locale}.${namespace}`] === RequestStatus.Success,
    );
  };

export const translate =
  (messages: Messages): TranslateFn =>
  (path, defaultValue, toReplace = emptyReplace) =>
    get(messages, normalizePath(messages, path), defaultValue)?.replace(
      /%(\w+)%/g,
      (_: any, k: string) => `${toReplace[k] ?? `%${k}%`}`,
    );

export const translateHtml =
  (messages: Messages): TranslateHtmlFn =>
  (path, defaultValue, toReplace = emptyReplace) =>
    markupParser(
      get(messages, normalizePath(messages, path), defaultValue)?.replace(
        /%(\w+)%/g,
        (_: any, k: string) => `${toReplace[k] ?? `%${k}%`}`,
      ),
    );

export const translateGroup =
  (messages: Messages): TranslateGroupFn =>
  (rawGroupKey) => {
    const groupKey = sanitizeKey(rawGroupKey);
    const innerKeys = Object.keys(messages).filter((key) => key.startsWith(groupKey));
    const collectionValues: Record<string, any> = {};

    innerKeys.forEach((k) => {
      set(collectionValues, k, messages[k]);
    });

    return get(collectionValues, groupKey) || [];
  };

export const extractNamespace = (key: Namespace): string => {
  const ns = isString(key) ? key : key.name;
  const split = ns.split('.');
  if (ns.indexOf(transversePrefix) === 0) {
    return `${transversePrefix}${split[1]}`;
  }
  return split[0];
};

export const normalizeNamespace = (ns: Namespace): NamespaceDescriptor =>
  isString(ns) ? { name: ns } : ns;

export const normalizeNamespaces = (namespaces: Namespace[]): NamespaceDescriptor[] =>
  namespaces.map(normalizeNamespace);

export const reduceNamespaces = (namespaces: Namespace[], countryCode: string): string[] =>
  namespaces.reduce((result, rawNamespace) => {
    const namespace = normalizeNamespace(rawNamespace);
    if (!Boolean(namespace.ifCountry && namespace.ifCountry !== countryCode)) {
      result.push(namespace.name);
    }
    return result;
  }, [] as string[]);
