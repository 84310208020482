/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Contract partitions
 * @export
 * @enum {string}
 */

export enum ContractPartEnum {
    Contract = 'contract',
    Company = 'company',
    AddressHeadquarters = 'addressHeadquarters',
    AddressCorrespondence = 'addressCorrespondence',
    Finance = 'finance',
    Services = 'services',
    Documents = 'documents',
    Products = 'products',
    Transactions = 'transactions',
    Locations = 'locations',
    Employees = 'employees',
    ProductLocations = 'productLocations',
    Invoices = 'invoices',
    Packages = 'packages',
    Performance = 'performance',
    RemittanceSheet = 'remittance_sheet'
}



