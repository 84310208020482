import { extendTheme } from '@chakra-ui/react';
import { theme as plxTheme } from '@pluxee-design-system/react';
import DashboardCard from 'common/cards/DashboardCard.theme';
import GridCard from 'common/cards/GridCard.theme';
import ProductCard from 'common/cards/ProductCard.theme';
import FaqSideMenuItem from 'common/overlays/FaqSideMenuItem.theme';
import Modal from 'common/overlays/Modal.theme';

export const theme = extendTheme({
  ...plxTheme,
  breakpoints: {
    ...plxTheme.breakpoints,
    mdd: '960px',
  },
  components: {
    ...plxTheme.components,
    DatePicker: {
      ...plxTheme.components.DatePicker,
      baseStyle: {
        ...plxTheme.components.DatePicker.baseStyle,
        dateInput: {
          ...plxTheme.components.DatePicker.baseStyle.dateInput,
          width: '100%',
        },
        datePicker: {
          ...plxTheme.components.DatePicker.baseStyle.datePicker,
          '.react-datepicker-wrapper': {
            width: '100%',
          },
        },
      },
    },
    // TODO: remove when Hamburger icon officially added to Header
    Header: {
      ...plxTheme.components.Header,
      baseStyle: {
        ...plxTheme.components.Header.baseStyle,
        navigation: {
          ...plxTheme.components.Header.baseStyle.navigation,
          display: 'flex',
        },
      },
    },
    Link: {
      ...plxTheme.components.Link,
      variants: {
        ...plxTheme.components.Link.variants,
        primary: {
          ...plxTheme.components.Link.variants.primary,
          text: {
            borderBottom: '0.125rem solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
          },
        },
      },
    },
    Modal: {
      ...plxTheme.components.Modal,
      sizes: {
        ...plxTheme.components.Modal.sizes,
        ...Modal.sizes,
      },
    },
    DashboardCard,
    GridCard,
    ProductCard,
    FaqSideMenuItem,
  },
  styles: {
    ...plxTheme.styles,
    global: {
      ...plxTheme.styles.global,
      body: {
        ...plxTheme.styles.global.body,
        bg: 'semantic.background.primary',
        color: 'semantic.text.primary',
        fontFamily: 'body',
      },
    },
  },
});
