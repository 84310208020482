import { RadioGroup, useBreakpointValue } from '@chakra-ui/react';
import { Icons, SvgSpriteConsumer } from '@pluxee-design-system/react';
import { PAGE_SIZE, useContracts } from 'api/contractApi';
import { InputControl } from 'common/forms';
import VirtualizedList from 'components/VirtualizedList';
import { FormikType } from 'context/ModalContext';
import useCurrentContract from 'data/useCurrentContract';
import { Form, Formik, FormikValues } from 'formik';
import { AssignedContract } from 'generated/models';
import { TranslateFn } from 'i18n';
import { debounce } from 'lodash';
import { ChangeEvent, forwardRef, MutableRefObject, useMemo, useState } from 'react';
import RenderRow from './RenderRow';
import { blockEnterKey } from './utils';

interface ContractSwitcherProps {
  onSubmit: (values: FormikValues) => void;
  t: TranslateFn;
}

const ContractSwitcher = (
  { onSubmit, t }: ContractSwitcherProps,
  ref: MutableRefObject<FormikType | null>,
) => {
  const itemSizeBx = useBreakpointValue({ base: 151, md: 111 }, { ssr: false, fallback: 'md' });
  const itemSize = itemSizeBx || 111;
  const { contractId } = useCurrentContract();
  const [query, setQuery] = useState('');
  const debouncedSetQuery = useMemo(
    () => debounce((value: string) => setQuery(value), 1000),
    [setQuery],
  );
  const { data, hasNextPage, isEmpty, isLoadingPage, loadNextPage } = useContracts(query);

  const initialValues = useMemo(
    () => ({
      search: '',
      contractId: contractId || '',
    }),
    [contractId],
  );

  return (
    <Formik enableReinitialize innerRef={ref} initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleChange, setFieldValue }) => (
        <Form onKeyDown={blockEnterKey}>
          <InputControl
            type="text"
            name="search"
            id="ContractSwitcher_search"
            placeholder={t('global_texts.modals.selectCompany.search.label', 'Search')}
            leftElement={<SvgSpriteConsumer size={24} svgId={Icons.SEARCH24} />}
            onChange={(event: ChangeEvent<any>) => {
              debouncedSetQuery(event.target.value);
              handleChange(event);
            }}
            mb="6"
          />
          <RadioGroup
            value={values.contractId}
            name="contractId"
            position="relative"
            minHeight={`${itemSize}px`}
            maxHeight={`calc(100vh - 25rem)`}
            onChange={(contractId) => setFieldValue('contractId', contractId)}
            overflowY="auto"
            overflowX="auto"
            overscrollBehavior="contain"
          >
            <VirtualizedList
              highlightItem={(row: AssignedContract) => row.contract?.uid === values.contractId}
              filterState={query}
              hasNextPage={hasNextPage}
              isEmpty={isEmpty}
              isNextPageLoading={isLoadingPage}
              loadNextPage={loadNextPage}
              items={data}
              itemSize={itemSize}
              height={`calc(100vh - 25rem)`}
              pageSize={PAGE_SIZE}
              pReachedEnd={0}
              reachedEndText={t('global_texts.labels.noResults', 'No options matching a query')}
              render={RenderRow}
              width="100%"
            />
          </RadioGroup>
        </Form>
      )}
    </Formik>
  );
};

export default forwardRef<FormikType, ContractSwitcherProps>(ContractSwitcher);
