import { defineStyleConfig } from '@chakra-ui/react';
import { InputTheme, InputVariant } from '@pluxee-design-system/react';

const ProductCard = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    padding: 4,
  },
  variants: {
    disabled: {
      // cursor: "not-allowed",
      backgroundColor: 'semantic.background.disabled',
      opacity: 1,
      color: 'semantic.text.disabled',
      borderColor: 'transparent',
      _placeholder: {
        color: 'semantic.text.disabled',
      },
      _hover: {
        _disabled: {
          backgroundColor: 'semantic.background.disabled',
          color: 'semantic.text.disabled',
          borderColor: 'transparent',
          _placeholder: {
            color: 'semantic.text.disabled',
          },
        },
      },
    },
    ...(InputTheme.variants?.filled
      ? { [InputVariant.FILLED]: InputTheme.variants?.filled()?.field }
      : {}),
    ...(InputTheme.variants?.error
      ? { [InputVariant.ERROR]: InputTheme.variants?.error()?.field }
      : {}),
    ...(InputTheme.variants?.success
      ? { [InputVariant.SUCCESS]: InputTheme.variants?.success()?.field }
      : {}),
    [InputVariant.READONLY]: {
      backgroundColor: 'field',
    },
    selected: {
      ...InputTheme.variants?.success()?.field,
      borderColor: 'semantic.interactive.primary',
    },
    static: {
      background: 'semantic.surface.1',
      border: '1px solid',
      borderColor: 'semantic.border.secondary',
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: InputVariant.FILLED,
  },
});

export default ProductCard;
