import { Box, SystemStyleObject } from '@chakra-ui/react';
import { InputVariant, Select, SelectOption, SelectProps } from '@pluxee-design-system/react';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import { isArray } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { MultiValue, PropsValue, SingleValue } from 'react-select';

export interface SelectControlProps
  extends Omit<SelectProps, 'isDisabled' | 'name' | 'options' | 'variant'> {
  defaultOptionLabel?: string;
  disabled?: boolean;
  id?: string;
  isArrayValue?: boolean;
  isCompact?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  name: string;
  options: readonly SelectOption[];
  onAfterChange?: () => void;
  loadingText?: string;
  notFoundText?: string;
  readonly?: boolean;
  skipVariant?: boolean;
  withDefaultOption?: boolean;
  sortAlphabetically?: boolean;
}

// TODO: get rid-of formik
const SelectControl = ({
  defaultOptionLabel = 'All',
  disabled,
  id,
  isArrayValue = false,
  isCompact = false,
  isMulti,
  isSearchable = true,
  name,
  loadingText = 'Getting data...',
  notFoundText = 'No options',
  onAfterChange,
  readonly,
  skipVariant = false,
  sortAlphabetically = true,
  field,
  field: { setTouched, setValue },
  withDefaultOption,
  ...selectProps
}: SelectControlProps & WithFastFieldProps<null | string | string[]>) => {
  const hasError = Boolean(field.error);
  const loadingFn = useMemo(() => () => loadingText, [loadingText]);
  const notFoundFn = useMemo(() => () => notFoundText, [notFoundText]);
  const handleChange = useCallback(
    (newValue: SingleValue<SelectOption> | MultiValue<SelectOption>) => {
      if (isMulti) {
        setValue((newValue as MultiValue<SelectOption>).map((option) => option.value));
      } else {
        const newOption = newValue as SingleValue<SelectOption>;
        setValue(
          isArrayValue ? (newOption?.value ? [newOption?.value] : []) : newOption?.value ?? null,
        );
      }
      onAfterChange?.();
    },
    [setValue, isArrayValue, isMulti, onAfterChange],
  );
  const handleBlur = useCallback(() => setTouched(true), [setTouched]);
  const options = useMemo(
    () =>
      sortAlphabetically
        ? selectProps.options?.slice()?.sort((a, b) => a.label?.localeCompare(b.label))
        : selectProps.options,
    [selectProps.options, sortAlphabetically],
  );
  const extendedOptions = useMemo(
    () =>
      withDefaultOption
        ? [{ value: null as unknown as string, label: defaultOptionLabel }, ...(options ?? [])]
        : options,
    [defaultOptionLabel, options, withDefaultOption],
  );
  const value = useMemo<PropsValue<SelectOption>>(() => {
    const val = isArrayValue && Array.isArray(field.value) ? field.value?.[0] : field.value;

    if (isMulti) {
      const normalizedValue = isArray(val) ? val : [val];
      const usedValues = new Set<string>();
      // add present options
      const result = options.filter(({ value: optionValue }) => {
        if (normalizedValue.includes(optionValue)) {
          usedValues.add(optionValue);
          return true;
        }
        return false;
      });
      // values not present in options are added
      normalizedValue.forEach((val) => {
        if (val && !usedValues.has(val)) {
          result.push({ value: val, label: val });
        }
      });
      return result as unknown as MultiValue<SelectOption>;
    }

    // not ===, only == compare '1' == 1
    return (options?.find((o) => 'value' in o && o.value == val) as SelectOption) ?? null;
  }, [isArrayValue, isMulti, field.value, options]);

  const compactSx: SystemStyleObject = isCompact
    ? { '.pluxee-select__control': { height: 'unset' } }
    : {};

  return (
    // work-around to disable transparent background
    <Box
      sx={
        readonly
          ? {
              '.pluxee-select__control--is-disabled': { background: 'field !important' },
              ...compactSx,
            }
          : { '.pluxee-select__menu': { zIndex: 2 }, ...compactSx }
      }
    >
      <Select
        {...field}
        {...selectProps}
        options={withDefaultOption ? (value === null ? options : extendedOptions) : options}
        value={selectProps.value ?? value}
        isDisabled={disabled}
        variant={
          skipVariant
            ? undefined
            : readonly
              ? InputVariant.READONLY
              : field.isTouched && hasError
                ? InputVariant.ERROR
                : field.isTouched
                  ? InputVariant.SUCCESS
                  : InputVariant.FILLED
        }
        onBlur={handleBlur}
        onChange={selectProps.onChange ?? handleChange}
        helpText={field.isTouched && hasError ? field.error : selectProps.helpText}
        // @ts-expect-error
        inputId={id}
        isMulti={isMulti}
        isSearchable={isSearchable}
        noOptionsMessage={notFoundFn}
        loadingMessage={loadingFn}
        blurInputOnSelect={false} // see https://stackoverflow.com/questions/64610967/validation-works-incorrectly-for-react-select-with-formik-on-mobile-device
      />
    </Box>
  );
};

const MemoizedSelectControl = memo(SelectControl);

export default withFastField(MemoizedSelectControl);
