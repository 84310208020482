import { CheckboxProps, VStack } from '@chakra-ui/react';
import {
  Checkbox,
  FieldHelpText,
  FieldTitle,
  InputVariant,
  SelectOption,
} from '@pluxee-design-system/react';
import ProductCard from 'common/cards/ProductCard';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import { union, without } from 'lodash';
import {
  ChangeEvent,
  ComponentType,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

export interface CheckboxGroupControlProps {
  disabled?: boolean;
  id?: string;
  mandatory?: boolean;
  name: string;
  options: readonly SelectOption[];
  placeholder?: ReactNode;
  readonly?: boolean;
  renderComponent?: ComponentType<SelectOption>;
  renderLeftElement?: ComponentType<SelectOption>;
  sortAlphabetically?: boolean;
  title?: string;
}

const ExtendedCheckbox = ({
  onChange,
  ...props
}: Omit<CheckboxProps, 'onChange'> & {
  disabled?: boolean;
  onChange: (id: string | number, value: boolean) => void;
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (props.value !== undefined) {
        onChange(props.value, event.target.checked);
      }
    },
    [onChange, props.value],
  );

  return <Checkbox {...props} onChange={handleChange} />;
};
const MemoizedCheckbox = memo(ExtendedCheckbox);

// inspired by https://gist.github.com/joshsalverda/d808d92f46a7085be062b2cbde978ae6
const CheckboxGroupControl = ({
  disabled,
  id,
  mandatory,
  name,
  options: rawOptions,
  readonly,
  renderComponent: RenderComponent,
  renderLeftElement: RenderLeftElement,
  sortAlphabetically = true,
  title,
  field,
  field: { setValue },
}: CheckboxGroupControlProps & WithFastFieldProps<Array<string | number>>) => {
  const fieldArray = useRef(field.value);
  const hasError = Boolean(field.error);
  const variant = readonly
    ? InputVariant.READONLY
    : disabled
    ? 'disabled'
    : field.isTouched && hasError
    ? InputVariant.ERROR
    : field.isTouched
    ? InputVariant.SUCCESS
    : InputVariant.FILLED;
  const options = useMemo(
    () =>
      sortAlphabetically
        ? rawOptions?.slice()?.sort((a, b) => a.label?.localeCompare(b.label))
        : rawOptions,
    [rawOptions, sortAlphabetically],
  );
  const handleChange = useCallback(
    (id: string | number, value: boolean) => {
      if (value) {
        fieldArray.current = union(fieldArray.current, [id]);
      } else {
        fieldArray.current = without(fieldArray.current, id);
      }
      setValue(fieldArray.current);
    },
    [setValue],
  );

  useEffect(() => {
    fieldArray.current = field.value;
  }, [field.value]);

  return (
    <>
      {title && (
        <FieldTitle
          title={title}
          mandatory={Boolean(mandatory)}
          disabled={variant === 'disabled'}
          variant={variant !== 'disabled' ? variant : undefined}
        />
      )}
      <VStack spacing={4} align="stretch">
        {options.map((option, index) => (
          <ProductCard
            key={option.value}
            variant={
              (variant === InputVariant.FILLED || variant === InputVariant.SUCCESS) &&
              field.value.includes(option.value)
                ? 'selected'
                : variant
            }
          >
            <MemoizedCheckbox
              id={`${id || name}.${index}`}
              name={name}
              isChecked={field.value.includes(option.value)}
              isDisabled={variant === 'disabled' || option.isDisabled}
              isInvalid={variant === InputVariant.ERROR}
              isReadOnly={variant === InputVariant.READONLY}
              value={option.value}
              onChange={handleChange}
              // mt={RenderComponent ? 3.5 : undefined}
            >
              {RenderLeftElement && <RenderLeftElement {...option} />}
              {RenderComponent ? undefined : option.label}
            </MemoizedCheckbox>
            {RenderComponent && <RenderComponent {...option} />}
          </ProductCard>
        ))}
      </VStack>
      {field.isTouched && field.error && (
        <FieldHelpText
          disabled={variant === 'disabled'}
          text={field.error}
          variant={InputVariant.ERROR}
        />
      )}
    </>
  );
};

const MemoizedCheckboxGroupControl = memo(CheckboxGroupControl);

export default withFastField(MemoizedCheckboxGroupControl);
