import { SimpleGrid } from '@chakra-ui/react';
import { InputControl } from 'common/forms/index';
import useTranslations from 'i18n';
import { identity } from 'lodash';
import { useMemo } from 'react';
import { ConfigField } from 'types/config';

interface AddressControlProps {
  config: {
    streetName: ConfigField;
    houseNumber: ConfigField;
    box?: ConfigField;
    city: ConfigField;
    postalCode: ConfigField;
  };
  disabled?: boolean;
  idPrefix: string;
  readonly?: boolean;
  tPrefix: string;
}

const AddressControl = ({ config, disabled, idPrefix, readonly, tPrefix }: AddressControlProps) => {
  const { t } = useTranslations();
  const streetColumns = useMemo(
    () =>
      [config.streetName.visible, config.houseNumber.visible, config.box?.visible].filter(identity)
        .length,
    [config.box?.visible, config.houseNumber.visible, config.streetName.visible],
  );

  return (
    <>
      {streetColumns > 0 && (
        <SimpleGrid columns={streetColumns} spacing={4}>
          {config.streetName.visible && (
            <InputControl
              readonly={readonly}
              disabled={disabled}
              type="text"
              mandatory={config.streetName.mandatory}
              name="streetName"
              id={`${idPrefix}_streetName`}
              placeholder={t(`${tPrefix}.streetName.label`, 'Street name')}
              title={t(`${tPrefix}.streetName.label`, 'Street name')}
            />
          )}
          {config.houseNumber.visible && (
            <InputControl
              readonly={readonly}
              disabled={disabled}
              type="text"
              mandatory={config.houseNumber.mandatory}
              name="houseNumber"
              id={`${idPrefix}_houseNumber`}
              placeholder={t(`${tPrefix}.houseNumber.label`, 'House number')}
              title={t(`${tPrefix}.houseNumber.label`, 'House number')}
            />
          )}
          {config.box?.visible && (
            <InputControl
              readonly={readonly}
              disabled={disabled}
              type="text"
              mandatory={config.box?.mandatory}
              name="box"
              id={`${idPrefix}_box`}
              placeholder={t(`${tPrefix}.box.label`, 'Box')}
              title={t(`${tPrefix}.box.label`, 'Box')}
            />
          )}
        </SimpleGrid>
      )}
      {(config.city.visible || config.postalCode.visible) && (
        <SimpleGrid columns={config.city.visible && config.postalCode.visible ? 2 : 1} spacing={4}>
          {config.city.visible && (
            <InputControl
              readonly={readonly}
              disabled={disabled}
              type="text"
              mandatory={config.city.mandatory}
              name="city"
              id={`${idPrefix}_city`}
              placeholder={t(`${tPrefix}.city.label`, 'City')}
              title={t(`${tPrefix}.city.label`, 'City')}
            />
          )}
          {config.postalCode.visible && (
            <InputControl
              readonly={readonly}
              disabled={disabled}
              type="text"
              mandatory={config.postalCode.mandatory}
              name="postalCode"
              id={`${idPrefix}_postalCode`}
              placeholder={t(`${tPrefix}.postalCode.label`, 'ZIP Code')}
              title={t(`${tPrefix}.postalCode.label`, 'ZIP Code')}
            />
          )}
        </SimpleGrid>
      )}
    </>
  );
};

export default AddressControl;
