import {
  Illustration as IllustrationRaw,
  IllustrationProps as IllustrationPropsRaw,
  PROD_CDN_BASE_URL,
} from '@pluxee-design-system/react';

interface IllustrationProps extends Omit<IllustrationPropsRaw, 'cdnUrl'> {}

const Illustration = (props: IllustrationProps) => (
  <IllustrationRaw {...props} cdnUrl={`${PROD_CDN_BASE_URL}/illustrations/`} />
);

export default Illustration;
