import { domToReact } from 'html-react-parser';

export interface NamespaceDescriptor {
  name: string;
  ifCountry?: string; // Conditional Fetching
}

export type Locale = string;
export type Namespace = string | NamespaceDescriptor;
export type NamespacesReadyFn = (countryCode: string, ...namespace: Namespace[]) => boolean;
export type Messages = Record<string, string>;
export type Value = string | null;
export type ToReplace = Record<string, boolean | number | string | undefined>;
export type TranslateFn = (key: string, defaultValue?: Value, toReplace?: ToReplace) => string;
export type TranslateHtmlFn = (
  key: string,
  defaultValue?: Value,
  toReplace?: ToReplace,
) => ReturnType<typeof domToReact>;
export type TranslateGroupFn = (groupKey: string) => Record<string, any>[];

export enum RequestStatus {
  Init,
  Waiting,
  Success,
  Error,
}

// string literal type (TS 4.1+)
export type RequestId = `${Locale}.${string}`;

export interface I18nRequest {
  namespaces: string[];
  locale: Locale;
  countryCode: string;
}

export interface I18nProps {
  nsReady: NamespacesReadyFn;
  t: TranslateFn;
  tGroup: TranslateGroupFn;
  tHtml: TranslateHtmlFn;
}

export type I18nContextLoaderType = (
  namespaces: Namespace[],
  locale: Locale,
  countryCode: string,
) => Promise<void>;

export interface I18nStateType {
  activeRequest: I18nRequest | null;
  requests: Record<RequestId, RequestStatus>;
  pendingRequests: I18nRequest[];
  messages: Record<Locale, Messages>;
  loadedMessages: Record<RequestId, RequestStatus>;
  ready: boolean;
}

/**
 * Reducer related types
 */
export enum ReducerActionType {
  QUEUE_REQUEST,
  COMPLETE_REQUEST,
}

export interface QueueRequestAction {
  type: ReducerActionType.QUEUE_REQUEST;
  request: I18nRequest;
}

export interface CompleteRequestAction {
  type: ReducerActionType.COMPLETE_REQUEST;
  request: I18nRequest;
  status: RequestStatus;
  messages: Messages[];
}

export type ReducerAction = QueueRequestAction | CompleteRequestAction;
