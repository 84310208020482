import getCsrfToken from 'data/getCsrfToken';
import { useCallback } from 'react';
import instance from 'utils/axios';

export async function signOut(redirect: boolean = true, callbackUrl?: string) {
  const signOutUrl = '/auth/signout';

  try {
    const response = await instance.post(
      signOutUrl,
      // @ts-expect-error
      new URLSearchParams({
        csrfToken: await getCsrfToken(),
        callbackUrl: callbackUrl ?? window.location.href,
        json: true,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    if (redirect) {
      const url = response?.data?.url ?? callbackUrl;
      window.location.href = url;
      // If url contains a hash, the browser does not reload the page. We reload manually
      if (url.includes('#')) window.location.reload();
      return;
    }
    return response?.data;
  } catch (e) {
    console.error(e);
  }
}

const useSignOut = (redirect: boolean = true, callbackUrl?: string) => {
  return useCallback(async () => signOut(redirect, callbackUrl), [callbackUrl, redirect]);
};

export default useSignOut;
