import Script from 'next/script';
import { CobrowsingConfig } from 'types/config';
import { getFullBasePath } from 'utils/router';

const cobrowse = {
  config: {} as CobrowsingConfig,

  init(config?: CobrowsingConfig) {
    if (!config?.orgGuid || !config?.orgId || !config?.orgName || !config?.deploymentKey)
      return null;

    this.config = config;

    return (
      <>
        <Script
          id="cobrowse-chat"
          src="https://apps.mypurecloud.de/webchat/jsapi-v1.js"
          strategy="afterInteractive"
        />
        <Script
          id="cobrowse-api"
          src={`https://cobrowse.mypurecloud.de/cobrowse-jsapi-v1.js?deploymentKey=${config.deploymentKey}`}
          strategy="afterInteractive"
        />
      </>
    );
  },
  start(currentLocale: string) {
    if (typeof window !== 'undefined' && window.ININ?.cobrowse?.create) {
      window.ININ.cobrowse
        .create({
          // Web chat application URL. Use the correct top-level domain for your Genesys Cloud region, i.e. apps.mypurecloud.ie, apps.mypurecloud.jp, apps.mypurecloud.co.uk.
          webchatAppUrl: 'https://apps.mypurecloud.de/webchat',

          // Web chat service URL
          webchatServiceUrl: 'https://realtime.mypurecloud.de:443',

          // Numeric organization ID
          orgId: this.config.orgId,

          // OrgGuid. Needed for co-browse with voice.
          orgGuid: this.config.orgGuid,

          // Organization name
          orgName: this.config.orgName,

          // Locale code
          locale: currentLocale,

          // CSS class applied to the security key window, but not its contents
          cssClass: 'webchat-frame',

          // Custom style applied to the security key window, but not its contents
          css: {
            width: '100%',
            height: '48px',
            border: '0',
            flex: '1 0 383px',
          },

          // Optional CSS for styling security key form
          contentCssUrl: `${getFullBasePath()}/assets/cobrowse.css`,

          // Element id where the security key form will appear.
          containerEl: 'cobrowse-security-form',

          // Set to true to display security key form. Do not include for co-browse with web chat or for PureConnect co-browse with voice.
          promptForSecurityKey: true,
        })
        .catch((err: any) => {
          console.error('cobrowse failed', err);
        });
    }
  },
};

export default cobrowse;
