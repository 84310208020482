import { InputVariant, PhoneInput, PhoneInputProps } from '@pluxee-design-system/react';
import CountryNames from 'common/countries';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import useConfig from 'data/useConfig';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Country, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';

interface PhoneControlProps
  extends Omit<
    PhoneInputProps,
    | 'countries'
    | 'defaultInputValue'
    | 'defaultSelectValue'
    | 'isDisabled'
    | 'name'
    | 'onChange'
    | 'variant'
  > {
  defaultCountry?: string;
  id?: string; // TODO: not supported
  name: string;
  onChange?: (newValue: string) => void;
  readonly?: boolean;
}

// Pluxee PhoneInput wraps a value with ( )
const normalizeValue = (newValue: string) =>
  parsePhoneNumber(newValue)?.number || newValue.replace('(', '').replace(')', '');

const parseFieldValue = (defaultCountryCode: Country, value: string, keyCount: number) => {
  const parsedInitialValue = parsePhoneNumber(value);
  return {
    keyCount,
    selectValue: `+${
      parsedInitialValue
        ? parsedInitialValue.countryCallingCode
        : getCountryCallingCode(defaultCountryCode)
    }`,
    inputValue: parsedInitialValue ? parsedInitialValue.nationalNumber : value ?? '',
  };
};

// TODO: get rid-of libraries: formik + react-phone-number-input
const PhoneControl = ({
  defaultCountry,
  name,
  readonly,
  field,
  field: { initialValue, onBlur, setValue },
  ...phoneProps
}: PhoneControlProps & WithFastFieldProps<string>) => {
  const hasError = Boolean(field.error);
  const lastValue = useRef('');
  const keyCount = useRef(0);
  const { callingCodes, isoCode } = useConfig();
  const defaultCountryCode = (defaultCountry ?? isoCode.toUpperCase()) as Country;
  const [defaultValues, setDefaultValues] = useState(() =>
    parseFieldValue(defaultCountryCode, initialValue, 0),
  );
  const countries = useMemo(
    () =>
      (callingCodes as Country[]).map((code) => ({
        name: CountryNames[code] || code,
        dial_code: `+${getCountryCallingCode(code)}`,
        code,
      })),
    [callingCodes],
  );
  const handleChange = useCallback(
    (newValue: string) => {
      const normalizedValue = normalizeValue(newValue);
      // hack to stop infinite loop, helpers.setValue is re-created on every field change :/
      if (lastValue.current !== normalizedValue) {
        lastValue.current = normalizedValue;
        if (
          normalizedValue &&
          normalizedValue !== field.value &&
          normalizedValue !== defaultValues.selectValue
        ) {
          setValue(normalizedValue);
        }
      }
    },
    [defaultValues.selectValue, setValue, field.value],
  );

  useEffect(() => {
    if (lastValue.current !== field.value) {
      const normalizedValue = normalizeValue(field.value);
      setDefaultValues(
        parseFieldValue(defaultCountryCode, normalizedValue, (keyCount.current += 1)),
      );
    }
  }, [defaultCountryCode, field.value, setDefaultValues]);

  return (
    <PhoneInput
      key={defaultValues.keyCount} // to force re-mount the component because DS is missing useEffect :-/
      {...phoneProps}
      onBlur={onBlur}
      onChange={phoneProps.onChange ?? handleChange}
      countries={countries}
      defaultInputValue={defaultValues.inputValue}
      defaultSelectValue={defaultValues.selectValue}
      variant={
        readonly
          ? InputVariant.READONLY
          : field.isTouched && hasError
            ? InputVariant.ERROR
            : field.isTouched
              ? InputVariant.SUCCESS
              : InputVariant.FILLED
      }
      helpText={field.isTouched && hasError ? field.error : phoneProps.helpText}
    />
  );
};

const MemoizedPhoneNumberControl = memo(PhoneControl);

export default withFastField(MemoizedPhoneNumberControl);
