import { ToastId } from '@chakra-ui/react';
import { UseToastOptions } from '@chakra-ui/toast';
import { Notification, PassiveVariants } from '@pluxee-design-system/react';
import useTranslations from 'i18n';

const getToastConfig = (toastType?: ToastId) => {
  switch (toastType) {
    case 200:
      return {
        variant: PassiveVariants.PASSIVE_SUCCESS,
        tKey: 'success',
      };
    case 400:
      return {
        variant: PassiveVariants.PASSIVE_WARNING,
        tKey: 'bad_request',
      };
    case 500:
      return {
        variant: PassiveVariants.PASSIVE_ERROR,
        tKey: 'server_error',
      };
    case 502:
    case 503:
    case 504:
      return {
        variant: PassiveVariants.PASSIVE_ERROR,
        tKey: 'gateway_error',
      };
    default:
      return {
        variant: PassiveVariants.PASSIVE_INFORMATION,
        tKey: 'info',
      };
  }
};

const Toast = ({ id, description, title }: UseToastOptions) => {
  const { tKey, variant } = getToastConfig(id);
  const { t } = useTranslations();

  return (
    <Notification
      description={
        (description as string) ??
        t(`global_texts.generalTexts.${tKey}.description`, 'Toast description')
      }
      title={(title as string) ?? t(`global_texts.generalTexts.${tKey}.title`, 'Toast title')}
      isClosable={false}
      variant={variant}
    />
  );
};

export default Toast;
