import { Center, SpinnerProps as ChakraSpinnerProps, } from '@chakra-ui/react';
import { Spinner, SpinnerType } from '@pluxee-design-system/react';

const Loader = () => (
  <Center width="100%" mb="auto" p={14}>
    <Spinner
      props={{ size: 'xl' } as ChakraSpinnerProps}
      type={SpinnerType.CIRCLE}
    />
  </Center>
);

export default Loader;
