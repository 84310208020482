import { Box, HStack, Text } from '@chakra-ui/react';
import {
  ButtonVariant,
  Footer as FooterUI,
  IconButton,
  Icons,
  SvgSpriteConsumer,
} from '@pluxee-design-system/react';
import env from 'env';
import { I18nProps, withLazyNamespaces } from 'i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { cobrowse } from 'trackers';

interface FooterProps extends I18nProps {
  currentLocale: string;
  hasCobrowsing: boolean;
}

const Footer = ({ currentLocale, hasCobrowsing, t, tGroup }: FooterProps) => {
  const [showCobrowse, setShowCobrowse] = useState(false);
  const hideCobrowse = useCallback(() => setShowCobrowse(false), [setShowCobrowse]);
  const leftElements = useMemo(
    () => [
      { key: 'copyright', children: t('ngm_menu.footer.copywriteInformation', '© Pluxee 2023') },
      ...tGroup('ngm_menu.footer.policyLinks').map(
        ({ cookie_settings: isCookiesSettings, link }) => ({
          key: link?.href,
          children: link?.title,
          ...(isCookiesSettings
            ? {
                to: '#',
                onClick: () => {
                  if (typeof window !== 'undefined' && window.OneTrust) {
                    window?.OneTrust?.ToggleInfoDisplay?.();
                  }
                },
              }
            : { href: link?.href, isExternal: true }),
        }),
      ),
    ],
    [t, tGroup],
  );
  const rightElements = useMemo(
    () => [
      {
        key: 'version',
        children: t('ngm_menu.footer.version', `Version ${env.rumVersion}`, {
          version: env.rumVersion,
        }),
      },
      ...(hasCobrowsing
        ? [
            {
              key: 'cobrowse',
              children: <SvgSpriteConsumer size={16} svgId={Icons.SCREEN16} />,
              to: '#',
              onClick: () => setShowCobrowse(true),
            },
          ]
        : []),
    ],
    [hasCobrowsing, setShowCobrowse, t],
  );

  useEffect(() => {
    if (showCobrowse) {
      const parent = document.getElementById('cobrowse-security-form');
      const iframe = parent?.getElementsByTagName('iframe');
      if (iframe?.[0]) parent?.removeChild(iframe[0]);

      cobrowse.start(currentLocale);
    }
  }, [currentLocale, showCobrowse]);

  return (
    <>
      <Box>
        <HStack
          display={showCobrowse ? 'flex' : 'none'}
          id="cobrowse-security-form"
          position="absolute"
          width="100%"
          left={0}
          height="120px"
          bottom={{ base: '74px', md: 12 }}
          background="semantic.surface.1"
          boxShadow="bottom"
          zIndex="1"
          py={4}
          pl={8}
          pr={14}
          spacing={2}
          align="center"
          mt="120px"
        >
          <Box>
            <Text variant="subtitle.section" color="semantic.text.tertiary" noOfLines={3}>
              {t('cobrowsing.header', 'Well done, you have found the screen sharing option')}
            </Text>
            <Text
              variant="subtitle.card"
              color="semantic.text.tertiary"
              hideBelow="md"
              noOfLines={3}
            >
              {t(
                'cobrowsing.description',
                'Are you currently in communication with one of our employees? Then enter the code that was sent to you. If this is not the case, you can simply ignore and close this window.',
              )}
            </Text>
          </Box>
          <Box position="absolute" right={4} top={4}>
            <IconButton
              aria-label="Close"
              variant={ButtonVariant.ICON}
              icon={<SvgSpriteConsumer svgId={Icons.DELETE24} size={24} />}
              onClick={hideCobrowse}
              size="sm"
            />
          </Box>
        </HStack>
      </Box>
      <FooterUI
        leftElements={leftElements}
        rightElements={rightElements}
        // zIndex={2}
        mt="auto"
      />
    </>
  );
};

export default withLazyNamespaces(Footer, 'ngm_menu', { name: 'cobrowsing', ifCountry: 'be' });
