import Script from 'next/script';
import { getFullBasePath } from 'utils/router';

const rum = {
  init(applicationId?: string, clientToken?: string, env?: string, service?: string, site?: string, version?: string) {
    // to disable Datadog RUM on local
    if (!applicationId || !clientToken || !env || !service || !site || !version) return null;
    const tracingUrl = getFullBasePath();

    return (
      <Script id="dd-rum" strategy="afterInteractive">
        {`(function(h,o,u,n,d) {
     h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
     d=o.createElement(u);d.async=1;d.src=n
     n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
  })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js','DD_RUM');
  window.DD_RUM.onReady(function() {
    window.DD_RUM.init({
      applicationId: '${applicationId}',
      clientToken: '${clientToken}',
      service: '${service}',
      site: '${site}',
      env: '${env}',
      version: '${version}',
      allowedTracingUrls: ${tracingUrl ? `['${tracingUrl}']` : undefined},
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0, 
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    window.DD_RUM.startSessionReplayRecording();
   });`}
      </Script>
    );
  },
};

export default rum;
