import { UseNumberInputProps } from '@chakra-ui/number-input/dist/use-number-input';
import { useNumberInput } from '@chakra-ui/react';
import { Input, InputProps, InputVariant } from '@pluxee-design-system/react';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import { memo, useCallback, useMemo } from 'react';

interface MoneyControlProps
  extends Omit<InputProps, 'isDisabled' | 'name' | 'value' | 'variant' | 'type'> {
  allowNegative?: boolean;
  precision?: number;
  thousandSeparator?: string;
  name: string;
  mandatory?: boolean;
  readonly?: boolean;
  skipVariant?: boolean;
}

const format = (x: number, separator = ' '): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

const parse = (x: string, separator = ' '): string => {
  return x.replaceAll(separator, '');
};

// TODO: get rid-of formik
const MoneyControl = ({
  allowNegative = false,
  disabled,
  name,
  thousandSeparator = ' ',
  precision = 0,
  readonly,
  skipVariant = false,
  field, // for FastField
  field: { setTouched, setValue },
  ...inputProps
}: MoneyControlProps & WithFastFieldProps<number | string>) => {
  const handleBlur = useCallback(() => setTouched(true), [setTouched]);
  const handleChange = useCallback(
    (_valueAsString: string, valueAsNumber: number) =>
      setValue(isNaN(valueAsNumber) ? '' : valueAsNumber),
    [setValue],
  );
  const props = useMemo<UseNumberInputProps>(
    () => ({
      name: field.name,
      id: inputProps.id,
      value: field.value,
      min: allowNegative ? undefined : Math.min(1, precision),
      isReadOnly: inputProps.isReadOnly || readonly,
      isDisabled: disabled,
      precision,
      onBlur: handleBlur,
      onChange: handleChange,
      format,
      parse,
    }),
    [
      allowNegative,
      disabled,
      field.name,
      field.value,
      handleBlur,
      handleChange,
      inputProps.id,
      inputProps.isReadOnly,
      precision,
      readonly,
    ],
  );
  const { getInputProps, isDisabled, isReadOnly, value: valueAsString } = useNumberInput(props);
  const hasError = Boolean(field.error);

  return (
    <Input
      {...inputProps}
      disabled={isReadOnly ? undefined : isDisabled}
      variant={
        skipVariant
          ? undefined
          : isReadOnly
            ? InputVariant.READONLY
            : field.isTouched && hasError
              ? InputVariant.ERROR
              : field.isTouched
                ? inputProps.rightElement
                  ? InputVariant.FILLED
                  : InputVariant.SUCCESS
                : InputVariant.FILLED
      }
      value={valueAsString}
      helpText={field.isTouched && hasError ? field.error : inputProps.helpText}
      {...getInputProps()}
    />
  );
};

const MemoizedMoneyControl = memo(MoneyControl);

export default withFastField(MemoizedMoneyControl);
