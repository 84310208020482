import { AssignedContract, ContractApiFactory, LocationItem } from 'generated';
import { instanceApi } from 'utils/axios';
import { toArrayOrUndefined, trimFulltext } from 'common/filters';
import { ProductLocationFilterValues } from 'components/Form/LocationSelect/types';
import usePagedRequest, { onMountSWRInfiniteConfig } from './usePagedRequest';
import useRequest, { onMountSWRConfig } from './useRequest';

// Misc
export const PAGE_SIZE: number = 5;
const contractApi = ContractApiFactory(undefined, '', instanceApi);

// Types
type ContractsParams = Parameters<typeof contractApi.contracts>;

export const useContracts = (query: string | undefined = undefined, enabled: boolean = true) =>
  usePagedRequest<AssignedContract, typeof contractApi.contracts, ContractsParams>(
    contractApi.contracts,
    (pageIndex, pageSize) => [pageIndex, pageSize, trimFulltext(query)] as ContractsParams,
    enabled,
    PAGE_SIZE,
    onMountSWRInfiniteConfig,
  );

export const useMyContract = (contractId?: string) =>
  useRequest(contractApi.myContract, [contractId], undefined, contractId !== undefined);

export const useContractDetail = (contractId?: string) =>
  useRequest(contractApi.contractRead, [contractId], {}, contractId !== undefined);

export const useContractProducts = (contractId?: string) =>
  useRequest(contractApi.contractProducts, [contractId], {}, contractId !== undefined);

export const useContractProductLocations = (
  contractId?: string,
  productId?: string,
  fetchOnce = false,
  filterState?: Partial<ProductLocationFilterValues>,
) =>
  usePagedRequest<LocationItem, typeof contractApi.contractProductLocationsGet>(
    contractApi.contractProductLocationsGet,
    (pageIndex, pageSize) => [
      contractId!,
      undefined,
      productId,
      toArrayOrUndefined(filterState?.states),
      filterState?.inactive,
      filterState?.approvedOnly,
      filterState?.withPaperProduct,
      trimFulltext(filterState?.fulltext),
      pageIndex,
      pageSize,
      filterState?.sortBy || undefined,
      filterState?.sortOrder === 'desc',
    ],
    contractId !== undefined,
    20,
    fetchOnce ? undefined : {},
  );

export const useContractStates = (contractId?: string) =>
  useRequest(contractApi.contractStates, [contractId], {}, contractId !== undefined);

export const useDashboardEmployees = (contractId?: string) =>
  useRequest(contractApi.contractLandingpageEmployees, [contractId], onMountSWRConfig, contractId !== undefined);

export const useDashboardLocations = (contractId?: string) =>
  useRequest(contractApi.contractLandingpageLocations, [contractId], onMountSWRConfig, contractId !== undefined);

export default contractApi;
