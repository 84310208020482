import {
  Button,
  IllustrationHeaderVariant,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
} from '@pluxee-design-system/react';
import { FormikProps, FormikValues } from 'formik';
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

interface ModalProviderProps {
  children: ReactNode;
}

export type FormikChildFn = (
  formikFormRef: MutableRefObject<FormikType>,
  onSubmit: (values: FormikValues) => void,
  onOk: () => void,
  onClose: () => void,
) => ReactNode;

export type FormikType = FormikProps<FormikValues> | null;

interface CustomModalParams {
  custom: (onOk: () => void, onClose: () => void) => ReactNode;
}

interface BasicModalParams {
  cancelId?: string;
  cancelText?: string;
  confirmId?: string;
  confirmText?: string;
  children?: ReactNode | FormikChildFn;
  hideCloseButton?: boolean;
  ignoreClickOutside?: boolean;
  onOk?: () => void;
  reverseOrder?: boolean; // to switch actions buttons
  title: string;
  subTitle?: string;
  size?: 'xxl';
  text?: ReactNode;
  illustration?: {
    variant?: `${IllustrationHeaderVariant}`;
    withoutArrow?: boolean;
    illustration: React.ReactNode;
  };
}

export type ShowModalParams = BasicModalParams | CustomModalParams;

export type ModalContextType = (params: ShowModalParams) => Promise<boolean | FormikValues>;

type ResolverFn = (value: boolean | FormikValues) => void;

const ModalContext = createContext<ModalContextType>(() => Promise.resolve(false));
const isCustom = (modalParams?: ShowModalParams): modalParams is CustomModalParams => {
  if (!modalParams) return false;
  const custom = (modalParams as CustomModalParams)?.custom;
  return custom !== undefined && custom !== null;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const formikFormRef = useRef<FormikType>(null);
  const [modalProps, setModalProps] = useState<ShowModalParams | undefined>(undefined);
  const customHandleOk = isCustom(modalProps) ? undefined : modalProps?.onOk;
  const resolver = useRef<ResolverFn>();
  const closeModal = useCallback(() => {
    if (formikFormRef?.current) {
      formikFormRef.current = null; // reset
    }
    setModalProps(undefined);
  }, [setModalProps]);

  const handleShow = useCallback<ModalContextType>(
    (params: ShowModalParams) => {
      setModalProps(params);

      return new Promise(function (resolve: ResolverFn) {
        resolver.current = resolve;
      });
    },
    [setModalProps],
  );

  const handleOk = useCallback(async () => {
    if (formikFormRef.current?.handleSubmit) {
      formikFormRef.current.handleSubmit();
    } else {
      customHandleOk?.();
      resolver.current?.(true);
      closeModal();
    }
  }, [closeModal, customHandleOk]);

  const handleFormSubmit = useCallback(
    (values: FormikValues) => {
      resolver.current?.(values);
      closeModal();
    },
    [closeModal],
  );

  const handleCancel = useCallback(() => {
    resolver.current?.(false);
    closeModal();
  }, [closeModal]);

  return (
    <ModalContext.Provider value={handleShow}>
      {modalProps &&
        (isCustom(modalProps) ? (
          modalProps.custom(handleOk, handleCancel)
        ) : (
          <Modal
            closeButtonVisible={!modalProps.hideCloseButton}
            closeOnEsc={!modalProps.hideCloseButton}
            closeOnOverlayClick={!(modalProps.ignoreClickOutside || modalProps.hideCloseButton)}
            illustrationHeader={modalProps.illustration}
            isOpen
            onClose={handleCancel}
            subTitle={modalProps.subTitle || undefined}
            title={modalProps.title}
            // consumed by object spread, size would be undefined if size={modalProps.size = undefined}
            {...(modalProps.size ? { size: modalProps.size } : {})}
          >
            <ModalBody>
              {modalProps.text && (
                <Text variant="body.largeMedium" color="semantic.text.tertiary">
                  {modalProps.text}
                </Text>
              )}
              {'function' === typeof modalProps.children
                ? modalProps.children(formikFormRef, handleFormSubmit, handleOk, handleCancel)
                : modalProps.children}
            </ModalBody>
            {(modalProps.cancelText || modalProps.confirmText) && (
              <ModalFooter>
                {modalProps.cancelText && (
                  <Button
                    id={modalProps.cancelId}
                    onClick={modalProps.reverseOrder ? handleOk : handleCancel}
                    variant="primaryOutlined"
                  >
                    {modalProps.cancelText}
                  </Button>
                )}
                {modalProps.confirmText && (
                  <Button
                    id={modalProps.confirmId}
                    onClick={modalProps.reverseOrder ? handleCancel : handleOk}
                    variant="primaryFilled"
                  >
                    {modalProps.confirmText}
                  </Button>
                )}
              </ModalFooter>
            )}
          </Modal>
        ))}
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};

export default useModal;
