import axios, { AxiosRequestConfig } from 'axios';
import https from 'https';
import getHeaders, { HeadersType } from './headers';
import {
  getErrorResponseInterceptor,
  getSuccessResponseInterceptor,
  ExcludedHttpCallbacksType,
  HttpCallbacksType,
} from './interceptors';

export interface ConfigType {
  baseURL?: string;
  headers?: HeadersType;
  httpCallbacks?: HttpCallbacksType;
  excludedHttpCallbacks?: ExcludedHttpCallbacksType;
  token?: string;
  axiosConfig?: AxiosRequestConfig;
}

const axiosFactory = (config: ConfigType) => {
  const {
    baseURL,
    token,
    headers,
    httpCallbacks = {},
    excludedHttpCallbacks = [],
    axiosConfig,
  } = config;

  const instance = axios.create({
    baseURL,
    headers: getHeaders(headers, token),
    httpsAgent: new https.Agent({ keepAlive: true }),
    ...axiosConfig,
  });

  instance.interceptors.response.use(
    getSuccessResponseInterceptor(httpCallbacks),
    getErrorResponseInterceptor(httpCallbacks, excludedHttpCallbacks),
  );

  return instance;
};

export default axiosFactory;
