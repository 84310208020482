/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AffiliationConfirmRequest } from '../models';
// @ts-ignore
import { AffiliationRequest } from '../models';
// @ts-ignore
import { ContractResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { FileDetailResponse } from '../models';
// @ts-ignore
import { FileType } from '../models';
// @ts-ignore
import { FindMeResponse } from '../models';
/**
 * AffiliationApi - axios parameter creator
 * @export
 */
export const AffiliationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * As main Employee, confirm the request for new merchant affiliation, if the affiliation was performed by BO user
         * @summary Confirm merchant affiliation request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {AffiliationConfirmRequest} affiliationConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationConfirm: async (contractUid: string, affiliationConfirmRequest: AffiliationConfirmRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('affiliationConfirm', 'contractUid', contractUid)
            // verify required parameter 'affiliationConfirmRequest' is not null or undefined
            assertParamExists('affiliationConfirm', 'affiliationConfirmRequest', affiliationConfirmRequest)
            const localVarPath = `/api/affiliation/{contractUid}/confirm`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(affiliationConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload the document, required for successful merchant affiliation (POA etc)
         * @summary Upload the document
         * @param {FileType} type Type of the file to upload
         * @param {any} file pdf, jpg, png
         * @param {string} [contractUid] Optional Contract identifier; if supplied, the File will be associated with the relevant entity straight away
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationFileUpload: async (type: FileType, file: any, contractUid?: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('affiliationFileUpload', 'type', type)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('affiliationFileUpload', 'file', file)
            const localVarPath = `/api/affiliation/file-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractUid !== undefined) {
                localVarQueryParameter['contractUid'] = contractUid;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find company information in the national register, check if the merchant is already affiliated
         * @summary Find company information
         * @param {string} identifier 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationFindMe: async (identifier: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('affiliationFindMe', 'identifier', identifier)
            const localVarPath = `/api/affiliation/find-me/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit the request for new merchant affiliation
         * @summary Merchant affiliation request
         * @param {AffiliationRequest} affiliationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationSubmit: async (affiliationRequest: AffiliationRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliationRequest' is not null or undefined
            assertParamExists('affiliationSubmit', 'affiliationRequest', affiliationRequest)
            const localVarPath = `/api/affiliation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(affiliationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AffiliationApi - functional programming interface
 * @export
 */
export const AffiliationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AffiliationApiAxiosParamCreator(configuration)
    return {
        /**
         * As main Employee, confirm the request for new merchant affiliation, if the affiliation was performed by BO user
         * @summary Confirm merchant affiliation request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {AffiliationConfirmRequest} affiliationConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliationConfirm(contractUid: string, affiliationConfirmRequest: AffiliationConfirmRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliationConfirm(contractUid, affiliationConfirmRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload the document, required for successful merchant affiliation (POA etc)
         * @summary Upload the document
         * @param {FileType} type Type of the file to upload
         * @param {any} file pdf, jpg, png
         * @param {string} [contractUid] Optional Contract identifier; if supplied, the File will be associated with the relevant entity straight away
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliationFileUpload(type: FileType, file: any, contractUid?: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliationFileUpload(type, file, contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find company information in the national register, check if the merchant is already affiliated
         * @summary Find company information
         * @param {string} identifier 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliationFindMe(identifier: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliationFindMe(identifier, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit the request for new merchant affiliation
         * @summary Merchant affiliation request
         * @param {AffiliationRequest} affiliationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliationSubmit(affiliationRequest: AffiliationRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliationSubmit(affiliationRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AffiliationApi - factory interface
 * @export
 */
export const AffiliationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AffiliationApiFp(configuration)
    return {
        /**
         * As main Employee, confirm the request for new merchant affiliation, if the affiliation was performed by BO user
         * @summary Confirm merchant affiliation request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {AffiliationConfirmRequest} affiliationConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationConfirm(contractUid: string, affiliationConfirmRequest: AffiliationConfirmRequest, options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.affiliationConfirm(contractUid, affiliationConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload the document, required for successful merchant affiliation (POA etc)
         * @summary Upload the document
         * @param {FileType} type Type of the file to upload
         * @param {any} file pdf, jpg, png
         * @param {string} [contractUid] Optional Contract identifier; if supplied, the File will be associated with the relevant entity straight away
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationFileUpload(type: FileType, file: any, contractUid?: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<FileDetailResponse> {
            return localVarFp.affiliationFileUpload(type, file, contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * Find company information in the national register, check if the merchant is already affiliated
         * @summary Find company information
         * @param {string} identifier 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationFindMe(identifier: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<FindMeResponse> {
            return localVarFp.affiliationFindMe(identifier, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit the request for new merchant affiliation
         * @summary Merchant affiliation request
         * @param {AffiliationRequest} affiliationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliationSubmit(affiliationRequest: AffiliationRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.affiliationSubmit(affiliationRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AffiliationApi - interface
 * @export
 * @interface AffiliationApi
 */
export interface AffiliationApiInterface {
    /**
     * As main Employee, confirm the request for new merchant affiliation, if the affiliation was performed by BO user
     * @summary Confirm merchant affiliation request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {AffiliationConfirmRequest} affiliationConfirmRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApiInterface
     */
    affiliationConfirm(contractUid: string, affiliationConfirmRequest: AffiliationConfirmRequest, options?: any): AxiosPromise<ContractResponse>;

    /**
     * Upload the document, required for successful merchant affiliation (POA etc)
     * @summary Upload the document
     * @param {FileType} type Type of the file to upload
     * @param {any} file pdf, jpg, png
     * @param {string} [contractUid] Optional Contract identifier; if supplied, the File will be associated with the relevant entity straight away
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApiInterface
     */
    affiliationFileUpload(type: FileType, file: any, contractUid?: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<FileDetailResponse>;

    /**
     * Find company information in the national register, check if the merchant is already affiliated
     * @summary Find company information
     * @param {string} identifier 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApiInterface
     */
    affiliationFindMe(identifier: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<FindMeResponse>;

    /**
     * Submit the request for new merchant affiliation
     * @summary Merchant affiliation request
     * @param {AffiliationRequest} affiliationRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApiInterface
     */
    affiliationSubmit(affiliationRequest: AffiliationRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

}

/**
 * AffiliationApi - object-oriented interface
 * @export
 * @class AffiliationApi
 * @extends {BaseAPI}
 */
export class AffiliationApi extends BaseAPI implements AffiliationApiInterface {
    /**
     * As main Employee, confirm the request for new merchant affiliation, if the affiliation was performed by BO user
     * @summary Confirm merchant affiliation request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {AffiliationConfirmRequest} affiliationConfirmRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public affiliationConfirm(contractUid: string, affiliationConfirmRequest: AffiliationConfirmRequest, options?: any) {
        return AffiliationApiFp(this.configuration).affiliationConfirm(contractUid, affiliationConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload the document, required for successful merchant affiliation (POA etc)
     * @summary Upload the document
     * @param {FileType} type Type of the file to upload
     * @param {any} file pdf, jpg, png
     * @param {string} [contractUid] Optional Contract identifier; if supplied, the File will be associated with the relevant entity straight away
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public affiliationFileUpload(type: FileType, file: any, contractUid?: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return AffiliationApiFp(this.configuration).affiliationFileUpload(type, file, contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find company information in the national register, check if the merchant is already affiliated
     * @summary Find company information
     * @param {string} identifier 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public affiliationFindMe(identifier: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return AffiliationApiFp(this.configuration).affiliationFindMe(identifier, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit the request for new merchant affiliation
     * @summary Merchant affiliation request
     * @param {AffiliationRequest} affiliationRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationApi
     */
    public affiliationSubmit(affiliationRequest: AffiliationRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return AffiliationApiFp(this.configuration).affiliationSubmit(affiliationRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
