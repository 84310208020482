/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorMessageResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { LocationActivitiesRequest } from '../models';
// @ts-ignore
import { LocationActivitiesResponse } from '../models';
// @ts-ignore
import { LocationAddressRequest } from '../models';
// @ts-ignore
import { LocationBusinessRequest } from '../models';
// @ts-ignore
import { LocationListResponse } from '../models';
// @ts-ignore
import { LocationPaymentErrorResponse } from '../models';
// @ts-ignore
import { LocationPaymentRequest } from '../models';
// @ts-ignore
import { LocationPaymentResponse } from '../models';
// @ts-ignore
import { LocationProductsRequest } from '../models';
// @ts-ignore
import { LocationRequest } from '../models';
// @ts-ignore
import { LocationResponse } from '../models';
// @ts-ignore
import { LocationSoftListResponse } from '../models';
// @ts-ignore
import { LocationStatesResponse } from '../models';
// @ts-ignore
import { LocationStatusEnum } from '../models';
// @ts-ignore
import { LocationsSortingEnum } from '../models';
// @ts-ignore
import { PaymentTypesResponse } from '../models';
// @ts-ignore
import { ProductsSelectionResponse } from '../models';
/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create the new location
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {LocationRequest} locationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractLocationCreate: async (contractUid: string, locationRequest: LocationRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractLocationCreate', 'contractUid', contractUid)
            // verify required parameter 'locationRequest' is not null or undefined
            assertParamExists('contractLocationCreate', 'locationRequest', locationRequest)
            const localVarPath = `/api/contract/{contractUid}/location`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Location activities list
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationActivities: async (contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('locationActivities', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/location-activities`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all contract related locations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
         * @param {boolean} [withPaperProduct] Items with attached paper product
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {LocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationList: async (contractUid: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('locationList', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/locations`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (states) {
                localVarQueryParameter['states'] = states.join(COLLECTION_FORMATS.csv);
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }

            if (approvedOnly !== undefined) {
                localVarQueryParameter['approvedOnly'] = approvedOnly;
            }

            if (withPaperProduct !== undefined) {
                localVarQueryParameter['withPaperProduct'] = withPaperProduct;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all contract related locations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {LocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationListExport: async (contractUid: string, includeInactive?: boolean, fulltext?: string, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('locationListExport', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/locations/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activate the location
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionActivate: async (locationUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationPartitionActivate', 'locationUid', locationUid)
            const localVarPath = `/api/location/{locationUid}/activate`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the location activity list
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationActivitiesRequest} locationActivitiesRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionActivities: async (locationUid: string, locationActivitiesRequest: LocationActivitiesRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationPartitionActivities', 'locationUid', locationUid)
            // verify required parameter 'locationActivitiesRequest' is not null or undefined
            assertParamExists('locationPartitionActivities', 'locationActivitiesRequest', locationActivitiesRequest)
            const localVarPath = `/api/location/{locationUid}/activities`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationActivitiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the location address
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationAddressRequest} locationAddressRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionAddress: async (locationUid: string, locationAddressRequest: LocationAddressRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationPartitionAddress', 'locationUid', locationUid)
            // verify required parameter 'locationAddressRequest' is not null or undefined
            assertParamExists('locationPartitionAddress', 'locationAddressRequest', locationAddressRequest)
            const localVarPath = `/api/location/{locationUid}/address`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the location data
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationBusinessRequest} locationBusinessRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionBusiness: async (locationUid: string, locationBusinessRequest: LocationBusinessRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationPartitionBusiness', 'locationUid', locationUid)
            // verify required parameter 'locationBusinessRequest' is not null or undefined
            assertParamExists('locationPartitionBusiness', 'locationBusinessRequest', locationBusinessRequest)
            const localVarPath = `/api/location/{locationUid}/business`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationBusinessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate the location
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionDeactivate: async (locationUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationPartitionDeactivate', 'locationUid', locationUid)
            const localVarPath = `/api/location/{locationUid}/deactivate`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the location product list
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationProductsRequest} locationProductsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionProducts: async (locationUid: string, locationProductsRequest: LocationProductsRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationPartitionProducts', 'locationUid', locationUid)
            // verify required parameter 'locationProductsRequest' is not null or undefined
            assertParamExists('locationPartitionProducts', 'locationProductsRequest', locationProductsRequest)
            const localVarPath = `/api/location/{locationUid}/products`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm the location payment
         * @param {string} paymentUid Location payment&#x60;uid&#x60;
         * @param {string} code Location payment confirmation code
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPaymentConfirm: async (paymentUid: string, code: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentUid' is not null or undefined
            assertParamExists('locationPaymentConfirm', 'paymentUid', paymentUid)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('locationPaymentConfirm', 'code', code)
            const localVarPath = `/api/location-payment/{paymentUid}/confirm`
                .replace(`{${"paymentUid"}}`, encodeURIComponent(String(paymentUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Init the location payment
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationPaymentRequest} locationPaymentRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPaymentInit: async (locationUid: string, locationPaymentRequest: LocationPaymentRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationPaymentInit', 'locationUid', locationUid)
            // verify required parameter 'locationPaymentRequest' is not null or undefined
            assertParamExists('locationPaymentInit', 'locationPaymentRequest', locationPaymentRequest)
            const localVarPath = `/api/location/{locationUid}/payment`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationPaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all products on contract and relation state to location
         * @summary List contract products with location relation state
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationProductsRead: async (locationUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationProductsRead', 'locationUid', locationUid)
            const localVarPath = `/api/location/{locationUid}/products`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Location full detail
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationRead: async (locationUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationRead', 'locationUid', locationUid)
            const localVarPath = `/api/location/{locationUid}`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationStates: async (locationUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('locationStates', 'locationUid', locationUid)
            const localVarPath = `/api/location/{locationUid}/states`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Location payment types list
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentTypes: async (contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('paymentTypes', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/payment-types`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all locations available for pos
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posLocationsSearch: async (posUid: string, fulltext?: string, pageNumber?: number, pageSize?: number, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posLocationsSearch', 'posUid', posUid)
            const localVarPath = `/api/pos/{posUid}/locations`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create the new location
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {LocationRequest} locationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractLocationCreate(contractUid: string, locationRequest: LocationRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractLocationCreate(contractUid, locationRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Location activities list
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationActivities(contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationActivitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationActivities(contractUid, ngmCountry, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all contract related locations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
         * @param {boolean} [withPaperProduct] Items with attached paper product
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {LocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationList(contractUid: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationList(contractUid, states, includeInactive, approvedOnly, withPaperProduct, fulltext, pageNumber, pageSize, sortBy, sortDesc, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all contract related locations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {LocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationListExport(contractUid: string, includeInactive?: boolean, fulltext?: string, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationListExport(contractUid, includeInactive, fulltext, sortBy, sortDesc, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activate the location
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationPartitionActivate(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationPartitionActivate(locationUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the location activity list
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationActivitiesRequest} locationActivitiesRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationPartitionActivities(locationUid: string, locationActivitiesRequest: LocationActivitiesRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationPartitionActivities(locationUid, locationActivitiesRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the location address
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationAddressRequest} locationAddressRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationPartitionAddress(locationUid: string, locationAddressRequest: LocationAddressRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationPartitionAddress(locationUid, locationAddressRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the location data
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationBusinessRequest} locationBusinessRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationPartitionBusiness(locationUid: string, locationBusinessRequest: LocationBusinessRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationPartitionBusiness(locationUid, locationBusinessRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate the location
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationPartitionDeactivate(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationPartitionDeactivate(locationUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the location product list
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationProductsRequest} locationProductsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationPartitionProducts(locationUid: string, locationProductsRequest: LocationProductsRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationPartitionProducts(locationUid, locationProductsRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm the location payment
         * @param {string} paymentUid Location payment&#x60;uid&#x60;
         * @param {string} code Location payment confirmation code
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationPaymentConfirm(paymentUid: string, code: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationPaymentConfirm(paymentUid, code, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Init the location payment
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationPaymentRequest} locationPaymentRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationPaymentInit(locationUid: string, locationPaymentRequest: LocationPaymentRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationPaymentInit(locationUid, locationPaymentRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all products on contract and relation state to location
         * @summary List contract products with location relation state
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationProductsRead(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsSelectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationProductsRead(locationUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Location full detail
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationRead(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationRead(locationUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationStates(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationStatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationStates(locationUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Location payment types list
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentTypes(contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentTypes(contractUid, ngmCountry, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all locations available for pos
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posLocationsSearch(posUid: string, fulltext?: string, pageNumber?: number, pageSize?: number, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationSoftListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posLocationsSearch(posUid, fulltext, pageNumber, pageSize, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create the new location
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {LocationRequest} locationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractLocationCreate(contractUid: string, locationRequest: LocationRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.contractLocationCreate(contractUid, locationRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Location activities list
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationActivities(contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options?: any): AxiosPromise<LocationActivitiesResponse> {
            return localVarFp.locationActivities(contractUid, ngmCountry, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all contract related locations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
         * @param {boolean} [withPaperProduct] Items with attached paper product
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {LocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationList(contractUid: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationListResponse> {
            return localVarFp.locationList(contractUid, states, includeInactive, approvedOnly, withPaperProduct, fulltext, pageNumber, pageSize, sortBy, sortDesc, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all contract related locations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {LocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationListExport(contractUid: string, includeInactive?: boolean, fulltext?: string, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.locationListExport(contractUid, includeInactive, fulltext, sortBy, sortDesc, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activate the location
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionActivate(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationPartitionActivate(locationUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the location activity list
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationActivitiesRequest} locationActivitiesRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionActivities(locationUid: string, locationActivitiesRequest: LocationActivitiesRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationPartitionActivities(locationUid, locationActivitiesRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the location address
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationAddressRequest} locationAddressRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionAddress(locationUid: string, locationAddressRequest: LocationAddressRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationPartitionAddress(locationUid, locationAddressRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the location data
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationBusinessRequest} locationBusinessRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionBusiness(locationUid: string, locationBusinessRequest: LocationBusinessRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationPartitionBusiness(locationUid, locationBusinessRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate the location
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionDeactivate(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationPartitionDeactivate(locationUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the location product list
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationProductsRequest} locationProductsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPartitionProducts(locationUid: string, locationProductsRequest: LocationProductsRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationPartitionProducts(locationUid, locationProductsRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm the location payment
         * @param {string} paymentUid Location payment&#x60;uid&#x60;
         * @param {string} code Location payment confirmation code
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPaymentConfirm(paymentUid: string, code: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationPaymentResponse> {
            return localVarFp.locationPaymentConfirm(paymentUid, code, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Init the location payment
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {LocationPaymentRequest} locationPaymentRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPaymentInit(locationUid: string, locationPaymentRequest: LocationPaymentRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationPaymentResponse> {
            return localVarFp.locationPaymentInit(locationUid, locationPaymentRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * List all products on contract and relation state to location
         * @summary List contract products with location relation state
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationProductsRead(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ProductsSelectionResponse> {
            return localVarFp.locationProductsRead(locationUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Location full detail
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationRead(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationRead(locationUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationStates(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationStatesResponse> {
            return localVarFp.locationStates(locationUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Location payment types list
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentTypes(contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options?: any): AxiosPromise<PaymentTypesResponse> {
            return localVarFp.paymentTypes(contractUid, ngmCountry, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all locations available for pos
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posLocationsSearch(posUid: string, fulltext?: string, pageNumber?: number, pageSize?: number, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationSoftListResponse> {
            return localVarFp.posLocationsSearch(posUid, fulltext, pageNumber, pageSize, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - interface
 * @export
 * @interface LocationApi
 */
export interface LocationApiInterface {
    /**
     * 
     * @summary Create the new location
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {LocationRequest} locationRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    contractLocationCreate(contractUid: string, locationRequest: LocationRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse>;

    /**
     * 
     * @summary Location activities list
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationActivities(contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options?: any): AxiosPromise<LocationActivitiesResponse>;

    /**
     * 
     * @summary List all contract related locations
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
     * @param {boolean} [includeInactive] Include an inactive items into the result
     * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
     * @param {boolean} [withPaperProduct] Items with attached paper product
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {LocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationList(contractUid: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationListResponse>;

    /**
     * 
     * @summary List all contract related locations
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {boolean} [includeInactive] Include an inactive items into the result
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {LocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationListExport(contractUid: string, includeInactive?: boolean, fulltext?: string, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Activate the location
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationPartitionActivate(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse>;

    /**
     * 
     * @summary Edit the location activity list
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationActivitiesRequest} locationActivitiesRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationPartitionActivities(locationUid: string, locationActivitiesRequest: LocationActivitiesRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse>;

    /**
     * 
     * @summary Edit the location address
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationAddressRequest} locationAddressRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationPartitionAddress(locationUid: string, locationAddressRequest: LocationAddressRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse>;

    /**
     * 
     * @summary Edit the location data
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationBusinessRequest} locationBusinessRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationPartitionBusiness(locationUid: string, locationBusinessRequest: LocationBusinessRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse>;

    /**
     * 
     * @summary Deactivate the location
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationPartitionDeactivate(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse>;

    /**
     * 
     * @summary Edit the location product list
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationProductsRequest} locationProductsRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationPartitionProducts(locationUid: string, locationProductsRequest: LocationProductsRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse>;

    /**
     * 
     * @summary Confirm the location payment
     * @param {string} paymentUid Location payment&#x60;uid&#x60;
     * @param {string} code Location payment confirmation code
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationPaymentConfirm(paymentUid: string, code: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationPaymentResponse>;

    /**
     * 
     * @summary Init the location payment
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationPaymentRequest} locationPaymentRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationPaymentInit(locationUid: string, locationPaymentRequest: LocationPaymentRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationPaymentResponse>;

    /**
     * List all products on contract and relation state to location
     * @summary List contract products with location relation state
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationProductsRead(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ProductsSelectionResponse>;

    /**
     * 
     * @summary Location full detail
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationRead(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationResponse>;

    /**
     * 
     * @summary State of the latest request for each partition
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    locationStates(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationStatesResponse>;

    /**
     * 
     * @summary Location payment types list
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    paymentTypes(contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options?: any): AxiosPromise<PaymentTypesResponse>;

    /**
     * 
     * @summary List all locations available for pos
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    posLocationsSearch(posUid: string, fulltext?: string, pageNumber?: number, pageSize?: number, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LocationSoftListResponse>;

}

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI implements LocationApiInterface {
    /**
     * 
     * @summary Create the new location
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {LocationRequest} locationRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public contractLocationCreate(contractUid: string, locationRequest: LocationRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).contractLocationCreate(contractUid, locationRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Location activities list
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationActivities(contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options?: any) {
        return LocationApiFp(this.configuration).locationActivities(contractUid, ngmCountry, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all contract related locations
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
     * @param {boolean} [includeInactive] Include an inactive items into the result
     * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
     * @param {boolean} [withPaperProduct] Items with attached paper product
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {LocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationList(contractUid: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationList(contractUid, states, includeInactive, approvedOnly, withPaperProduct, fulltext, pageNumber, pageSize, sortBy, sortDesc, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all contract related locations
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {boolean} [includeInactive] Include an inactive items into the result
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {LocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationListExport(contractUid: string, includeInactive?: boolean, fulltext?: string, sortBy?: LocationsSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationListExport(contractUid, includeInactive, fulltext, sortBy, sortDesc, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activate the location
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationPartitionActivate(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationPartitionActivate(locationUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the location activity list
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationActivitiesRequest} locationActivitiesRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationPartitionActivities(locationUid: string, locationActivitiesRequest: LocationActivitiesRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationPartitionActivities(locationUid, locationActivitiesRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the location address
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationAddressRequest} locationAddressRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationPartitionAddress(locationUid: string, locationAddressRequest: LocationAddressRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationPartitionAddress(locationUid, locationAddressRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the location data
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationBusinessRequest} locationBusinessRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationPartitionBusiness(locationUid: string, locationBusinessRequest: LocationBusinessRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationPartitionBusiness(locationUid, locationBusinessRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate the location
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationPartitionDeactivate(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationPartitionDeactivate(locationUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the location product list
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationProductsRequest} locationProductsRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationPartitionProducts(locationUid: string, locationProductsRequest: LocationProductsRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationPartitionProducts(locationUid, locationProductsRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm the location payment
     * @param {string} paymentUid Location payment&#x60;uid&#x60;
     * @param {string} code Location payment confirmation code
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationPaymentConfirm(paymentUid: string, code: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationPaymentConfirm(paymentUid, code, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Init the location payment
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {LocationPaymentRequest} locationPaymentRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationPaymentInit(locationUid: string, locationPaymentRequest: LocationPaymentRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationPaymentInit(locationUid, locationPaymentRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all products on contract and relation state to location
     * @summary List contract products with location relation state
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationProductsRead(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationProductsRead(locationUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Location full detail
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationRead(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationRead(locationUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary State of the latest request for each partition
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationStates(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).locationStates(locationUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Location payment types list
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [acceptLanguage] Standard HTTP header for preferred locale. If possible, use the two-character format (e.g. &#x60;cs&#x60;, &#x60;en&#x60;).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public paymentTypes(contractUid: string, ngmCountry?: 'cz' | 'be', acceptLanguage?: string, options?: any) {
        return LocationApiFp(this.configuration).paymentTypes(contractUid, ngmCountry, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all locations available for pos
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public posLocationsSearch(posUid: string, fulltext?: string, pageNumber?: number, pageSize?: number, ngmCountry?: 'cz' | 'be', options?: any) {
        return LocationApiFp(this.configuration).posLocationsSearch(posUid, fulltext, pageNumber, pageSize, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
