import { HStack, StackProps, useStyleConfig } from '@chakra-ui/react';
import { InputVariant } from '@pluxee-design-system/react';
import { ReactNode } from 'react';

interface ProductCardProps extends StackProps {
  variant: InputVariant | 'disabled' | 'selected' | 'static';
  children: ReactNode;
}

const ProductCard = ({ children, variant, ...hStackProps }: ProductCardProps) => {
  const styles = useStyleConfig('ProductCard', { variant });

  return (
    <HStack spacing={4} __css={styles} align="flex-start" {...hStackProps}>
      {children}
    </HStack>
  );
};

export default ProductCard;
