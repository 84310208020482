import { useCallback } from 'react';
import useModal from 'context/ModalContext';
import { TranslateFn } from 'i18n';
import { contractSwitcherModalParams } from 'components/ContractSwitcher';
import useCurrentContract from 'data/useCurrentContract';

const useContractSwitcher = (t: TranslateFn, callback?: () => void, enabled = true) => {
  const showModal = useModal();
  const { contract: currentContract, setContractId } = useCurrentContract(enabled);

  const handleClick = useCallback(async () => {
    const modalResult = await showModal(contractSwitcherModalParams(t));
    const values = typeof modalResult === 'object' ? modalResult : undefined;
    if (values && values?.contractId) {
      await setContractId(values.contractId);
      callback && callback();
    }
  }, [callback, setContractId, showModal, t]);

  return { currentContract, handleClick };
};

export default useContractSwitcher;
