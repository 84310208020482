import { Box, Flex, MenuItem, Text } from '@chakra-ui/react';
import {
  NavigationItem,
  NestingMenuItemProps,
  SvgSpriteConsumer,
} from '@pluxee-design-system/react';
import { useHamburgerMenuContext } from 'components/Navbar/HamburgerMenuContext';
import React from 'react';

const GoBackButtonIcon = () => (
  <Flex color="semantic.interactive.primary">
    <SvgSpriteConsumer svgId="arrowLeft24" size={24} />
  </Flex>
);

const getContentForParent = (
  children: React.ReactNode,
  resetContentToParent: () => void,
  backButtonWithDivider?: boolean,
  goBackNavigationTitle?: string,
) => (
  <>
    <NavigationItem
      isBackItem
      closeOnSelect={false}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        resetContentToParent();
      }}
      title={goBackNavigationTitle || 'Go back'}
      icon={<GoBackButtonIcon />}
      withDivider={backButtonWithDivider}
    />
    {children}
  </>
);

const HamburgerNestedMenu = ({
  children,
  icon,
  title,
  withDivider,
  backButtonWithDivider,
  goBackNavigationTitle,
}: NestingMenuItemProps) => {
  const setContentToDisplay = useHamburgerMenuContext();
  return (
    <MenuItem
      closeOnSelect={false}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setContentToDisplay(
          getContentForParent(
            children,
            setContentToDisplay,
            backButtonWithDivider,
            goBackNavigationTitle,
          ),
        );
      }}
      {...(withDivider
        ? {
            borderBottom: '1px',
            borderBottomColor: 'semantic.border.secondary',
          }
        : {})}
    >
      <Flex justifyContent="space-between" alignItems="center" flexGrow="1" mr="-1.5">
        <Flex>
          {icon ? (
            <Box height="6" mr="2">
              {icon}
            </Box>
          ) : null}
          <Flex flexDirection="column">
            <Text variant="body.largeDemibold" noOfLines={2}>
              {title}
            </Text>
          </Flex>
        </Flex>
        <Flex color="semantic.interactive.primary">
          <SvgSpriteConsumer svgId="chevronRight24" size={24} />
        </Flex>
      </Flex>
    </MenuItem>
  );
};

export default HamburgerNestedMenu;
