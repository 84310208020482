import { IllustrationHeaderVariant, Illustrations } from '@pluxee-design-system/react';
import Illustration from 'common/Illustration';
import HtmlText from 'common/typography/HtmlText';
import { ShowModalParams } from 'context/ModalContext';
import { TranslateFn, TranslateHtmlFn } from 'i18n';

export const globalInfoModal = (t: TranslateFn, tHtml: TranslateHtmlFn): ShowModalParams => {
  const illustrationId = t('global_info_message.modal.illustration', '');
  return {
    title: t('global_info_message.modal.header', ''),
    children: (
      <HtmlText as="div" variant="body.largeMedium" color="semantic.text.tertiary">
        {tHtml('global_info_message.modal.description', '')}
      </HtmlText>
    ),
    ignoreClickOutside: true,
    subTitle: t('global_info_message.modal.subheader', ''),
    illustration: illustrationId
      ? {
          illustration: (
            <Illustration illustrationId={illustrationId as Illustrations} width={24} />
          ),
          variant: t(
            'global_info_message.modal.severity',
            IllustrationHeaderVariant.INFO,
          ) as IllustrationHeaderVariant,
        }
      : undefined,
  };
};
